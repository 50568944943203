import Card from './Card';
import styles from './index.module.css';

const MedicalDeliveryService = () => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.content}>
        <div className={styles.LeftTextContainer}>
          <h1>Autonomous Monitoring and Medical Delivery Service</h1>
          <div className={styles.dividerPill}> </div>
          <p className={styles.docReview}>
            “robot automation is relieving our staff from monotonous tasks while
            improving service quality”.
          </p>
          <p className={styles.docName}>
            -Rob S. <br /> MD Hospital, UK
          </p>
        </div>
        <div className={styles.imageContainer}>
          <img
            className={styles.img}
            alt='robots'
            src={require('../../assets/imgs/medicalRobots.png')}
          />
        </div>
        <div className={styles.RightTextContainer}>
          <Card
            label='Delivering Medicines to Patients'
            Img={
              <img
                alt='medicines'
                src={require('../../assets/imgs/medicines.png')}
              />
            }
          />
          <Card
            label='Carrying Medical instruments'
            Img={
              <img
                alt='medicines'
                src={require('../../assets/imgs/medicalHistoryPills.png')}
              />
            }
          />
          <Card
            label='Doctors can interact (Telepresence)'
            Img={
              <img
                alt='medicines'
                src={require('../../assets/imgs/medicalDoc.png')}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MedicalDeliveryService;
