import React from "react";
import "../styles/footer.css";
import logo from "../assets/imgs/footerLogo.png";
import { CONTACT_US_EMAIL, MAIL_CONTACT_US_EMAIL } from "../constants/Links";

const Footer = () => {
  const about_us_options = [
    {
      verbose: "Our Ditributors",
      path: "/distributors",
    },
    { verbose: "Privacy Policy", path: "/privacy-policy" },
    { verbose: "Terms of Use", path: "/terms-and-conditions" },
  ];
  const sos_med_icons = [
    {
      verbose: "Instagram",
      // image_source: require("../../assets/icons/instagram.png").default,
      link: "https://www.instagram.com/swiftrobotics.ai/",
    },
    {
      verbose: "Linkedin",
      // image_source: require("../../assets/icons/linkedin.png").default,
      link: "https://www.linkedin.com/company/swiftrobots",
    },
    {
      verbose: "Twitter",
      // image_source: require("../../assets/icons/twitter.png").default,
      link: "https://twitter.com/swiftrobots",
    },
    {
      verbose: "Youtube",
      // image_source: require("../../assets/icons/youtube.png").default,
      link: "https://www.youtube.com/@swift-robotics",
    },
  ];
  return (
    <div className="footer">
      <img src={logo} alt="img" className="footer-logo" />
      <div className="footer__links">
        {/* <div>
          <h5>About us </h5>
          <div className="aboutUs__links">
            {about_us_options.map((item, index) => {
              return (
                <Link to={item.path} key={index}>
                  <h6 className="aboutUs__item">{item.verbose}</h6>
                </Link>
              );
            })}
          </div>
        </div> */}
        <div className="footerLinkCont">
          <h5>Contact us </h5>
          <a href={MAIL_CONTACT_US_EMAIL} className="footer-link">
            <h6 className="aboutUs__item">{CONTACT_US_EMAIL}</h6>
          </a>
        </div>
        <div className="footerLinkCont">
          <h5>Connect with us </h5>
          <div className="aboutUs__links">
            {sos_med_icons.map((item, index) => {
              return (
                <a
                  href={item.link}
                  target="_blank"
                  className="footer-link"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <h6 className="aboutUs__item">{item.verbose}</h6>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
