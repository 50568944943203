import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/imgs/logo.png';
import '../styles/header.css';
const Header = () => {
  const [open, setOpen] = useState(false);
  return (
    <header className='header'>
      <div className={'header__container'}>
        <div className='header__brand-container'>
          <Link className='header__brand' to='/'>
            <img className='header-img' src={logo} alt='Swift RObotics' />
          </Link>
        </div>
      </div>
      {/* <nav className="header__nav">
          <NavLink className="header__nav-link" exact activeClassName="active" to="/">Home</NavLink>
                    <NavLink className="header__nav-link" activeClassName="active" to="/about">About us</NavLink>
                    <NavLink className="header__nav-link" activeClassName="active" to="/product">Our Products</NavLink>
                    <NavLink className="header__nav-link" activeClassName="active" to="/contact">Contact</NavLink>
        </nav> */}
      {/* <button className="header__menubtn" onClick={() => setOpen(!open)}>
          <span></span>
          <span></span>
          <span></span>
        </button> */}
      {/* <nav
        className={`${
          open ? 'header__navOpen' : ''
        } header__nav header__navMob`}
      >
         <NavLink className="header__nav-link" exact activeClassName="active" to="/">Home</NavLink>
                <NavLink className="header__nav-link" activeClassName="active" to="/about">About us</NavLink>
                <NavLink className="header__nav-link" activeClassName="active" to="/product">Our Products</NavLink>
                <NavLink className="header__nav-link" activeClassName="active" to="/contact">Contact</NavLink> 
      </nav> */}
    </header>
  );
};

export default Header;
