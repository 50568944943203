import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import EndingBanner from '../components/EndingBanner';
import multiTray from '../assets/imgs/multiTray.png';
import tenmindelivery from '../assets/imgs/tenmindelivery.png';

import Carousel1_1 from '../assets/imgs/carousal/1.png';
import Carousel1_2 from '../assets/imgs/carousal/2.png';
import Carousel1_3 from '../assets/imgs/carousal/3.png';

// categories
import Accessories from '../assets/imgs/categories/accessories.png';
import Cafe from '../assets/imgs/categories/cafe.png';
import Electronics from '../assets/imgs/categories/electronics.png';
import Food from '../assets/imgs/categories/food.png';

import MobileSS from '../assets/imgs/mobile-ss.png';

import OrderIcon from '../assets/imgs/icons/order-icon.svg';
import PayIcon from '../assets/imgs/icons/pay-icon.svg';
import ScanIcon from '../assets/imgs/icons/scan-icon.svg';

import Playstore from '../assets/imgs/store/playstore.png';
import Appstore from '../assets/imgs/store/appstore.png';

import CustomerSatIcon from '../assets/imgs/icons/customer-sat.svg';
import FastDeliveryIcon from '../assets/imgs/icons/fast-delivery.svg';
import ReliableIcon from '../assets/imgs/icons/reliable.svg';

import cycleguy from '../assets/imgs/cycleguy.png';
import sixtymin from '../assets/imgs/homedelivery.png';

import bottomVideo from '../assets/videos/bottomVideo.mp4';
import videoMain from '../assets/videos/main.mp4';

import secondSec2Img from '../assets/imgs/2ndSection.png';
import mainVideoPlaceholder from '../assets/imgs/main_video_placeholder.jpg';
import airportImage from '../assets/imgs/airport.png';
import hospitalImage from '../assets/imgs/hosp.jpeg';

import '../styles/home.css';
import { APP_STORE_LINK, PLAY_STORE_LINK } from '../constants/Links';
import BenefitsInHospitals from '../sections/BenefitsInHospitals';
import MedicalDeliveryService from '../sections/MedicalDeliveryService';

const Home = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  function handleContactUsLink() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }

  const [bottomVideoVisible, setBottomVideoVisible] = useState(false);

  return (
      <div className='home'>
          <div className='local-video-container'>
              {!bottomVideoVisible && (
                  <img
                      className='video-placeholder-img'
                      src={mainVideoPlaceholder}
                      alt=''
                  />
              )}
              <video
                  autoPlay
                  muted
                  loop
                  id='myVideo'
                  onLoadedData={() => setBottomVideoVisible(true)}
                  className='local-video'
              >
                  <source src={videoMain} type='video/mp4' width='100%' height='100%'/>
              </video>
          </div>


          <MedicalDeliveryService/>

          <div className='second-section'>
              <img
                  className='video-placeholder-img'
                  src={hospitalImage}
                  alt=''
              />
          </div>

          <BenefitsInHospitals/>
          <div className='second-section'>
              <img
                  className='video-placeholder-img'
                  src={airportImage}
                  alt=''
              />
          </div>
          <div className='ending__banner_container'>
              <EndingBanner/>
          </div>
      </div>
  );
};

export default Home;
