import styles from './index.module.css';

const Card = ({ Img, label }) => {
  return (
    <div className={styles.rightCard}>
      <span className={styles.eclipse}>{Img}</span>
      <p className={styles.cardText}>{label}</p>
    </div>
  );
};

export default Card;
